@font-face {
  font-family: 'eyedee';
  src:  url('fonts/eyedee.eot?nknzyx');
  src:  url('fonts/eyedee.eot?nknzyx#iefix') format('embedded-opentype'),
    url('fonts/eyedee.ttf?nknzyx') format('truetype'),
    url('fonts/eyedee.woff?nknzyx') format('woff'),
    url('fonts/eyedee.svg?nknzyx#eyedee') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'eyedee' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-calendar:before {
  content: "\e933";
}
.icon-camera-flip:before {
  content: "\e932";
}
.icon-2D:before {
  content: "\e92f";
}
.icon-3D:before {
  content: "\e930";
}
.icon-revit:before {
  content: "\e931";
}
.icon-price-tag:before {
  content: "\e926";
}
.icon-speech-bubble:before {
  content: "\e927";
}
.icon-share-link:before {
  content: "\e928";
}
.icon-waste:before {
  content: "\e929";
}
.icon-lock-private:before {
  content: "\e92a";
}
.icon-pad-lock:before {
  content: "\e92b";
}
.icon-download:before {
  content: "\e92c";
}
.icon-us-dollar-circled:before {
  content: "\e92d";
}
.icon-external-link:before {
  content: "\e92e";
}
.icon-gallery:before {
  content: "\e925";
}
.icon-gallery-image:before {
  content: "\e924";
}
.icon-photo-upload:before {
  content: "\e923";
}
.icon-copy:before {
  content: "\e920";
}
.icon-facebook:before {
  content: "\e921";
}
.icon-whatsapp:before {
  content: "\e922";
}
.icon-microphone-mute:before {
  content: "\e91f";
}
.icon-clock-rotate-left:before {
  content: "\e91e";
}
.icon-ellipsis-circle:before {
  content: "\e91c";
}
.icon-ellipsis-dot:before {
  content: "\e91d";
}
.icon-arrow-left:before {
  content: "\e900";
}
.icon-arrow-right:before {
  content: "\e901";
}
.icon-assembly:before {
  content: "\e902";
}
.icon-brochure:before {
  content: "\e903";
}
.icon-buildings:before {
  content: "\e904";
}
.icon-camera:before {
  content: "\e905";
}
.icon-chevron-down:before {
  content: "\e906";
}
.icon-chevron-left:before {
  content: "\e907";
}
.icon-chevron-right:before {
  content: "\e908";
}
.icon-circle-plus:before {
  content: "\e909";
}
.icon-cleaning:before {
  content: "\e90a";
}
.icon-close:before {
  content: "\e90b";
}
.icon-customize:before {
  content: "\e90c";
}
.icon-envelope:before {
  content: "\e90d";
}
.icon-heart:before {
  content: "\e90e";
}
.icon-location-dot:before {
  content: "\e90f";
}
.icon-lock:before {
  content: "\e910";
}
.icon-magnifying-glass:before {
  content: "\e911";
}
.icon-materials:before {
  content: "\e912";
}
.icon-microphone:before {
  content: "\e913";
}
.icon-minus:before {
  content: "\e914";
}
.icon-mobile:before {
  content: "\e915";
}
.icon-plus:before {
  content: "\e916";
}
.icon-price-list:before {
  content: "\e917";
}
.icon-share:before {
  content: "\e918";
}
.icon-sustainability:before {
  content: "\e919";
}
.icon-user:before {
  content: "\e91a";
}
.icon-warranty:before {
  content: "\e91b";
}
